.minimised-container {
  max-height: 5rem;
  mask: linear-gradient(to bottom,
          rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 40%,
          rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
  ) 100% 50% / 100% 100% repeat-x;
  cursor: pointer;

  a{
    pointer-events: none;
  }
  button{
    pointer-events: none;
  }
}