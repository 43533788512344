.product-type-variable {
  font-weight: bold;
  cursor: pointer;
  div {
    cursor: pointer;
  }
}

.graph {
  &-container {
    display: flex;
    flex-direction: column;
  }

  &-legend {
    display: block;
    font-size: 0.8em;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 85%;
    margin: 8px auto 0 auto;

    &-item {
      label {
        margin: 0 6px;

        &:hover {
          cursor: pointer;
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}