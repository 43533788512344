.quantity-cancelled {
  color: #f44336;
}

.refund-quantity {
  width: 40px;
}
.refund-quantity input {
  padding: 5px 10px;
}

.refund-order-line {
  width: 80px;
}
.refund-order-line input {
  padding: 5px 10px;
}

.refund-shipping {
  width: 80px;
}
.refund-shipping input {
  padding: 5px 10px;
}

.refund-unassigned {
  width: 80px;
}
.refund-unassigned input {
  padding: 5px 10px;
}