.shipment-item-container {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    height: fit-content;
    transition: all 10s ease-in; //TODO: get working

    .shipment-item {
        display: inherit;
        flex-direction: row;
        gap: 1rem;
        justify-content: space-between;

        input[type=checkbox] {
            align-self: baseline;
            margin-top: 4px;
            cursor: pointer;
        }

        input[type=checkbox]:disabled {
            cursor: not-allowed;
        }

        .item-information-container {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: -webkit-fill-available;

            .item-information {
                display: inherit; 
                justify-content: space-between;
            }
        }
    }
}

.shipment-item-container.minimised-container {
    max-height: 5rem;
    mask: linear-gradient(to bottom, 
        rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 40%, 
        rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
    ) 100% 50% / 100% 100% repeat-x;
    cursor: pointer;
}

