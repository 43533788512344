.widgets {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;
    width: 100%;
    margin-bottom: 30px;
}

.error {
    width: 100%;
    font-weight: 500;
    padding: 15px;

    border-radius: 6px;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
}

.widget {
    flex-basis: calc(20% - 12px);

    padding: 15px;

    border-radius: 6px;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);

    @media all and (max-width: 1000px) {
        flex-basis: calc(33.33% - 10px);

        &:nth-child(4) {
            margin-left: 0 !important;
        }

        &:nth-child(n+4) {
            margin-top: 15px;
        }
    }

    @media all and (max-width: 560px) {
        flex-basis: calc(50% - 8px);

        &:nth-child(2n+1) {
            margin-left: 0 !important;
        }

        &:nth-child(n+3) {
            margin-top: 15px;
        }

        &:nth-child(4) {
            margin-left: 15px !important;
        }
    }

    &Label {
        font-size: 16px;
        font-weight: 600;
        line-height: 1;

        margin-top: 0;
        margin-bottom: 10px;
    }

    &Counts {
        width: 100%;
        margin: 0;
        padding: 0;

        list-style-type: none;

        li {
            display: flex;
            align-items: center;

            font-size: 14px;
            font-weight: 500;

            & > span {
                display: inline-block;

                &:first-child {
                    flex-grow: 1;
                }
            }

            @media all and (max-width: 560px) {
                flex-direction: column;
                align-items: flex-start;

                & > span:last-child {
                    display: flex;
                    flex-direction: row-reverse;
                }
            }
        }
    }

    & + & {
        margin-left: 15px;
    }

    .percentageChange {
        position: relative;
    
        display: inline-flex;
        align-items: center;
        justify-content: center;
    
        font-weight: bold;
        font-size: 12px;

        margin: 0 5px;
    
        &::before {
            content: '';
            
            display: none;
            margin-right: 1px;
            
            border-top: 5px solid;
            border-right: 5px solid;
            border-bottom: 5px solid;
            border-left: 5px solid;
            
            border-color: transparent;
        }
    
        &.negative {
            color: red;
            
            &::before {
                display: block;
                
                border-top: 5px solid red;
                transform: translateY(calc(50% - 2.5px));
            }
        }
    
        &.positive {
            color: green;
            
            &::before {
                display: block;
                
                border-bottom: 5px solid green;
                transform: translateY(calc(-50% + 2.5px));
            }
        }
    }
}