.footer {
  padding: 10px;
  position: sticky;
  bottom: 0;
  background: #fafafa;
  z-index: 1;
  margin-top: 20px !important;
  border-radius: 5px;

  button {
    margin-right: 10px;
  }
}

.quantity-table-cell {
  padding-top: 10px;
  padding-bottom: 10px;
}
