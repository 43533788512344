/*
there is currently no way to disable chonky's search field without removing the entire action bar
below approach is approved here by author: https://github.com/TimboKZ/Chonky/issues/74
also, chonky only supports fuzzy search which we don't want so have to implement our own
https://github.com/TimboKZ/Chonky/issues/19
*/
div.chonky-searchFieldContainer {
    display: none;
}

div.resourceOpenInNew {
    display:none;
    opacity: 0.0;
    z-index: 1000;
}

tr:hover div.resourceOpenInNew {
    display:block;
    position: absolute;
    right: 0;
    margin-right: 0.5em;
    background: white;
    opacity: 0.85;
    transition: all 1s;
}