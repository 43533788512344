.widget {
    width: 100%;
    margin-bottom: 30px;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    background-color: white;

    overflow: hidden;

    &Title {
        margin: 0;
        font-size: 16px;
        padding: 24px;
        padding-bottom: 20px;
        border-bottom: 1px solid #efefef;
        font-weight: 600;

        span {
            display: block;
            margin-top: 2px;

            font-size: 14px;
            line-height: 1.5;
            font-weight: 400;

            color: #999;
        }
    }

    &Chart {
        width: 100%;
        padding: 24px;
        padding-top: 24px;
        padding-right: 20px;
        padding-bottom: 15px;
        padding-left: 0;
        margin-left: -8px;
    }

    &Totals {
        p {
            margin: 0;
            padding: 15px 24px;
            font-size: 16px;
            line-height: 1;
            font-weight: 600;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border-top: 1px solid #efefef;
        }
    }
}

.percentageChange {
    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    font-size: 14px;
    font-style: normal;

    margin: 0 5px;

    &::before {
        content: '';
        
        display: none;
        margin-right: 1px;
        
        border-top: 5px solid;
        border-right: 5px solid;
        border-bottom: 5px solid;
        border-left: 5px solid;
        
        border-color: transparent;
    }

    &.negative {
        color: red;
        
        &::before {
            display: block;
            
            border-top: 5px solid red;
            transform: translateY(calc(50% - 2.5px));
        }
    }

    &.positive {
        color: green;
        
        &::before {
            display: block;
            
            border-bottom: 5px solid green;
            transform: translateY(calc(-50% + 2.5px));
        }
    }
}